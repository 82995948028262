<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-list-header lines="none">
      <ion-label>Nuevo Item</ion-label>
    </ion-list-header>
      <ion-item lines="inset">
        <ion-label position="stacked">Código</ion-label>
        <ion-input type="text" placeholder="Código" v-model="dataItem.codigo"></ion-input>
      </ion-item>
      <ion-item lines="inset">
        <ion-label position="stacked">Fecha</ion-label>
        <ion-datetime
          v-model="fechaInicio"
          min="2020"
          max="2025"
          display-format="DDDD DD/MM/YYYY"
          :day-names="dayNames"
          cancel-text="Cancelar"
          done-text="Hecho"
          placeholder="Seleccionar fecha"
        ></ion-datetime>
      </ion-item>
      <ion-item lines="inset">
        <ion-label position="stacked">Item</ion-label>
        <ion-input type="text" placeholder="Item" v-model="dataItem.name"></ion-input>
      </ion-item>
      <ion-item lines="inset">
        <ion-label position="stacked">Descripción</ion-label>
        <ion-textarea rows="3" v-model="dataItem.description"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button type="submit" expand="block" fill="outline">Enviar</ion-button>
  </form>
</template>

<script>
import { IonList, IonItem, IonLabel, IonInput, IonDatetime, IonButton, IonTextarea, 
  loadingController, toastController, IonListHeader } from "@ionic/vue";
import {} from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "item-component",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonDatetime,
    IonButton,
    IonTextarea,
    IonListHeader
  },
  setup() {
    const dayNames = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];

    return {
      dayNames,
    };
  },
  data() {
    return {
      dataItem: {
        id: 0,
        userId : null,
        name: "",
        description: null,
        codigo: "",
        fecha: null,
      },
      fechaInicio: null,
      timeout:1000,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  methods: {
    ...mapActions("item", ["addItem"]),
    async submitForm() {
      this.timeout = 100000;
      const loading = await loadingController.create({
          message: "Enviando información...",
          duration: this.timeout,
        });
        await loading.present();
      this.dataItem.userId = this.user.id;
      this.dataItem.fecha = await this.timeConverter(this.fechaInicio);
      const result = await this.addItem({
        item: this.dataItem,
      });
      if (result.status === 201) {
          this.$router.replace({ name: "home" });
          this.toatsColor = "light";
          this.toatsMessage = "Registro exitoso";
          this.openToast();
      }else if (result.status === 500) {
          this.toatsColor = "danger";
          this.toatsMessage = `¡Error interno del servidor!.<br>Contacte al administrador`;
          this.openToast();
        } else if (result.status === 422) {
          let errorMessate = "";
          const errors = await JSON.parse(JSON.stringify(result.data.errors));
          for (const i in errors) {            
            errorMessate = await errors[i];
          }
          this.toatsColor = "danger";
          this.toatsMessage = `No se ha enviado toda la información<br>¡Erores!<br>${errorMessate}`;
          await this.openToast();
        } else {
          this.toatsColor = "danger";
          this.toatsMessage = "Error. No se ha realizado el registro";
          this.openToast();
        }
        loading.dismiss();
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
    async timeConverter(timestamp) {
      const a = new Date(timestamp);
      const year = a.getFullYear();
      const month = a.getMonth() + 1;
      const date = a.getDate();
      const hour = a.getHours();
      const min = a.getMinutes();
      const sec = a.getSeconds();
      const time = `${year}-${month}-${date} ${hour}:${min}:${sec}`;
      return time;
    },  
  },
};
</script>

<style>
</style>