<template>
  <base-layout>
  <div class="container">    
    <item-component></item-component>
  </div>
  </base-layout>
</template>

<script>
import ItemComponent from '../components/item/ItemComponent.vue'
export default {
  components: { ItemComponent },
    name: 'item-page',
}
</script>

<style>

</style>